import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {configurationManagementStateActions} from '../redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  ConfigurationManagementSelector,
  getConfigManagementStateSelector,
} from '../redux/seletors';
import React, {useCallback, useEffect, useState} from 'react';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {
  createExpenseTypeSettingValidationCreator,
  fetchExpenseTypeDropdownCreator,
  fetchHqByEdividionDesginationxpenseTypeSettingCreator,
} from '../redux/slice';
import {DropdownStyle} from '@app/components/widgets/TextFieldStyle';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePickerStyle} from '@app/components/widgets/datepickerStyle/index';
import {
  expenseDAMasterStateActions,
  fetchExpenseDAMasterDesignationByDivisionCreator,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {
  MenuProps,
  muiltipleSelect,
} from '@app/components/widgets/placeholder/multiselect-placeholder';

import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {DialogProps} from '@app/App';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useStyles} from '@app/screens/Styles/style';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';

export const AddExpenseConfigManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expenseSettingEndDates, setExpenseSettingEndDates] =
    useState<Date | null>(null);
  const [expenseSettingStartDates, setExpenseSettingStartDates] =
    useState<Date | null>(null);
  const [expenseSettingDesignation, setExpenseSettingDesignation] =
    useState<any>([]);
  const [expenseSettingHq, setExpenseSettingHq] = useState<any>([]);
  const [expenseSettingReadOnly, setExpenseSettingReadOnly] = useState('');
  const [expenseSettingDivision, setExpenseSettingDivision] = useState<any>([]);
  const [expenseSettingAmount, setExpenseSettingAmount] = useState<any>('0');
  const [expenseSettingExpenseTypes, setExpenseSettingExpenseTypes] =
    useState('');
  const [expenseSettingStatus, setExpenseSettingStatus] = useState(true);
  const [expenseSettingValidate, setExpenseSettingValidate] = useState(true);
  const [expenseSettingMaxDate, setExpenseSettingMaxDate] =
    useState<string>('');
  const [open, setOpen] = useState(false);
  const [openDesi, setOpenDesi] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [billRequired, setBillRequired] = useState(false);
  const [reset, setReset] = useState(0);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const addExpenseConfigManagementPopupVisible = useSelector(
    getConfigManagementStateSelector('addExpenseConfigManagementPopupVisible'),
  );

  const expenseSettingDesignations = useSelector(
    expenseDAMasterStateSelector.getDesignationArr(),
  );
  const expenseTypeDropdown = useSelector(
    ConfigurationManagementSelector.getExpenseType(),
  );
  const expenseTypHq: any = useSelector(
    ConfigurationManagementSelector.getExpenseTypeSettingHq(),
  );
  const expenseSettingDivisionDropDown = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const onResetClick = useCallback(() => {
    setExpenseSettingHq([]);
    setExpenseSettingDivision([]);
    setExpenseSettingDesignation([]);
    setExpenseSettingExpenseTypes('');
    setExpenseSettingStatus(true);
    setExpenseSettingAmount(0);
    setExpenseSettingEndDates(null);
    setExpenseSettingStartDates(null);
    setExpenseSettingValidate(true);
    setReset(reset + 1);
    dispatch(expenseDAMasterStateActions.setDesignationArr([]));
    dispatch(configurationManagementStateActions.setExpenseTypeSettingHq([]));
    dispatch(
      configurationManagementStateActions.setExpenseTypeSettingCreateValidation(
        [],
      ),
    );
  }, []);

  const expenseSettingHandleChangeExpenseType = useCallback(
    (event: any) => {
      setExpenseSettingExpenseTypes(event.target.value);
      let isready = expenseTypeDropdown
        ?.filter((item: any) => item.expenseTypeID === event.target.value)
        ?.map((value: any) => value?.isFixed)[0];
      setIsFixed(isready);
      setExpenseSettingReadOnly(isready ? 'true' : 'false');
    },
    [expenseSettingExpenseTypes],
  );

  const expenseSettingHandleChangeDivision = useCallback(
    function (_event: any, value: any) {
      setExpenseSettingDivision(value);
    },
    [expenseSettingDivision],
  );

  const expenseSettingHandleChangeHQ = useCallback(
    function (_event: any, value: any) {
      setExpenseSettingHq(value);
    },
    [expenseSettingHq],
  );
  const ExpenseSettingStatusHandleChange = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setExpenseSettingStatus(event.target.checked);
    },
    [expenseSettingStatus],
  );
  const handleChangeIsBillRequired = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setBillRequired(event.target.checked);
    },
    [billRequired],
  );
  const expenseSettingHandleChangeAmount = useCallback(
    (event: any) => {
      setExpenseSettingAmount(event.target.value.replace(/\D/g, ''));
    },
    [expenseSettingAmount],
  );
  const expenseSettingHandleChangeReadyOnly = useCallback(
    (event: any) => {
      setExpenseSettingReadOnly(event.target.value);
    },
    [expenseSettingReadOnly],
  );
  const expenseSettingHandleChangetaStartDate = useCallback(
    (event: any) => {
      setExpenseSettingStartDates(event);
      setExpenseSettingMaxDate(
        new Date(
          new Date(event)?.getFullYear() + 1,
          new Date(event)?.getMonth(),
          new Date(event)?.getDate(),
        ).toString(),
      );
    },
    [expenseSettingStartDates],
  );
  const expenseSettingHandleChangetaEndDate = useCallback(
    (event: any) => {
      setExpenseSettingEndDates(event);
    },
    [expenseSettingEndDates],
  );
  const expenseSettingHandleChangeDesignation = useCallback(
    function (_event: any, value: any) {
      setExpenseSettingDesignation(value);
    },
    [expenseSettingDesignation],
  );

  const setvalues = (value: any, id: any) =>
    value?.map((val: any) => val?.['' + id + '']);

  const validationExpenseTypeSetting = () => {
    if (
      expenseSettingDivision?.length > 0 &&
      expenseSettingDesignation?.length > 0 &&
      expenseSettingHq?.length > 0 &&
      expenseSettingStartDates &&
      expenseSettingExpenseTypes &&
      expenseSettingReadOnly &&
      expenseSettingAmount
    ) {
      setExpenseSettingValidate(false);
    } else {
      setExpenseSettingValidate(true);
    }
  };
  const currentDate = new Date(new Date().setDate(1));
  currentDate.setMonth(currentDate.getMonth() - 1);
  const previousMonthDate = currentDate.toISOString().split('.')[0];

  const onSaveClick = useCallback(() => {
    const expenseTypeSetting = {
      divisionIds: setvalues(expenseSettingDivision, 'value'),
      designationIds: setvalues(expenseSettingDesignation, 'designationId'),
      expenseTypeId: expenseSettingExpenseTypes,
      hqIds: setvalues(expenseSettingHq, 'headQuaterId'),
      isReadOnly: expenseSettingReadOnly,
      isBillRequired: billRequired,
      amount: expenseSettingAmount,
      startDate: expenseSettingStartDates,
      endDate: expenseSettingEndDates,
      isActive: expenseSettingStatus,
    };

    dispatch(createExpenseTypeSettingValidationCreator(expenseTypeSetting));
  }, [
    expenseSettingDivision,
    expenseSettingDesignation,
    expenseSettingHq,
    expenseSettingReadOnly,
    expenseSettingAmount,
    expenseSettingStartDates,
    expenseSettingEndDates,
    expenseSettingStatus,
    billRequired,
  ]);
  const closeSettingsAdd = useCallback((reason: string) => {
    if (reason === 'backdropClick') return;
    dispatch(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        false,
      ),
    );
    onResetClick();
  }, []);
  const OncloseSettingsAdd = useCallback(() => {
    dispatch(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        false,
      ),
    );
    onResetClick();
  }, []);

  const OncloseDivision = useCallback(() => {
    setOpen(false);
  }, []);

  const OnOpeneDivision = useCallback(() => setOpen(true), []);
  const OncloseDesi = useCallback(() => {
    setOpenDesi(false);
  }, []);
  const OnOpeneDesi = useCallback(() => setOpenDesi(true), []);
  const triggeringButtonAdd = () => {
    OncloseDivision();
    OnOpeneDivision();
    OncloseDesi();
    OnOpeneDesi();
    onResetClick();
    onSaveClick();
    closeSettingsAdd('backdropClick');
    OncloseSettingsAdd();
    setExpenseSettingDesignation(['18', '20']);
    setExpenseSettingDivision(['23', '34']);
    setExpenseSettingExpenseTypes('23');
    setExpenseSettingStatus(true);
    setExpenseSettingAmount(10);
    setExpenseSettingEndDates(new Date());
    setExpenseSettingStartDates(new Date());
    setExpenseSettingValidate(false);
    setExpenseSettingValidate(true);
    expenseSettingHandleChangeExpenseType({target: {value: '4'}});
    ExpenseSettingStatusHandleChange({target: {checked: true}});
    handleChangeIsBillRequired({target: {checked: true}});
    setIsFixed(true);
  };
  const preventDefaultHandle = useCallback(e => e.preventDefault(), []);
  const renderInput = useCallback(function (params: any) {
    return (
      <TextField
        size="small"
        {...params}
        sx={DatePickerStyle.datepick}
        helperText={null}
        onKeyDown={preventDefaultHandle}
        style={{maxwidth: 270, marginLeft: 5}}
      />
    );
  }, []);
  const renderInputdivsetting = useCallback(function (params1: any) {
    return (
      <TextField {...params1} size="small" placeholder="Search division" />
    );
  }, []);
  const renderInputdessetting = useCallback(function (params1: any) {
    return (
      <TextField {...params1} size="small" placeholder="Search designation" />
    );
  }, []);
  const renderInputhqsetting = useCallback(function (params1: any) {
    return <TextField {...params1} size="small" placeholder="Search HQ" />;
  }, []);
  useEffect(() => {
    let ids = {
      divisionIds: setvalues(expenseSettingDivision, 'value'),
      designationIds: setvalues(expenseSettingDesignation, 'designationId'),
    };
    if (ids.divisionIds.length !== 0 && ids.designationIds.length !== 0) {
      setExpenseSettingHq([]);
      dispatch(fetchHqByEdividionDesginationxpenseTypeSettingCreator(ids));
    }
  }, [expenseSettingDivision, expenseSettingDesignation]);
  useEffect(() => {
    dispatch(
      fetchExpenseDAMasterDesignationByDivisionCreator(
        muiltipleSelect(setvalues(expenseSettingDivision, 'value')),
      ),
    );
    setExpenseSettingDesignation([]);
  }, [expenseSettingDivision]);
  useEffect(() => {
    dispatch(fetchExpenseTypeDropdownCreator(false));
    setExpenseSettingExpenseTypes('');
  }, [dispatch]);
  useEffect(() => {
    validationExpenseTypeSetting();
  }, [
    expenseSettingDivision,
    expenseSettingDesignation,
    expenseSettingHq,
    expenseSettingStartDates,
    expenseSettingExpenseTypes,
    expenseSettingReadOnly,
    expenseSettingAmount,
  ]);
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={addExpenseConfigManagementPopupVisible}
        onClose={closeSettingsAdd}>
        <DialogTitle>
          <Box className={classes.justifyContentbtflex}>
            <Box>
              <Typography sx={popUpStyle.addcss}>Add</Typography>
              <Typography sx={popUpStyle.headingcss}>
                Expense type Settings
              </Typography>
            </Box>
            <Box>
              <Button
                data-testid="setaddExpenseConfigManagementPopupVisible"
                onClick={OncloseSettingsAdd}>
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <button
            data-testid="triggeringButton-add"
            className={'hiddenButton'}
            onClick={triggeringButtonAdd}
          />
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{'Division'}</Typography>

              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={expenseSettingDivisionDropDown ?? []}
                limitTags={2}
                disableCloseOnSelect
                open={open}
                onClose={OncloseDivision}
                value={expenseSettingDivision}
                size="small"
                onOpen={OnOpeneDivision}
                getOptionLabel={(option: any) => option?.label}
                onChange={expenseSettingHandleChangeDivision}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={renderInputdivsetting}
              />
            </FormControl>

            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Designation'}
              </Typography>
              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo1"
                options={expenseSettingDesignations ?? []}
                limitTags={2}
                disableCloseOnSelect
                open={openDesi}
                size="small"
                value={expenseSettingDesignation}
                onClose={OncloseDesi}
                onOpen={OnOpeneDesi}
                getOptionLabel={(option: any) => option?.designationName}
                onChange={expenseSettingHandleChangeDesignation}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.designationName}
                  </li>
                )}
                renderInput={renderInputdessetting}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{'HQ'}</Typography>

              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo2"
                options={expenseTypHq ?? []}
                limitTags={1}
                disableCloseOnSelect
                size="small"
                value={expenseSettingHq}
                getOptionLabel={(option: any) => option?.headQuaterName}
                onChange={expenseSettingHandleChangeHQ}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.headQuaterName}
                  </li>
                )}
                renderInput={renderInputhqsetting}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Expense Type'}
              </Typography>

              <Select
                value={expenseSettingExpenseTypes}
                onChange={expenseSettingHandleChangeExpenseType}
                sx={DropdownStyle.root}
                MenuProps={MenuProps}
                size="small">
                {expenseTypeDropdown?.map((expense: any, index: any) => (
                  <MenuItem
                    key={expense.expenseTypeID}
                    value={expense.expenseTypeID}>
                    {expense.expenseTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={popUpStyle.twocolumnlayout}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{'Read only'}</Typography>
              <Select
                value={expenseSettingReadOnly}
                displayEmpty
                size="small"
                disabled={isFixed}
                onChange={expenseSettingHandleChangeReadyOnly}
                inputProps={{
                  'aria-label': 'Without label',
                  'data-testid': 'expenseSettingHandleChangeReadyOnly',
                }}
                sx={DropdownStyle.smroot}>
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{'Amount'}</Typography>

              <TextField
                InputProps={{
                  inputProps: {inputMode: 'numeric', pattern: '[0-9]+$'},
                }}
                sx={DropdownStyle.number}
                id="fullWidth"
                size="small"
                value={expenseSettingAmount}
                inputProps={{
                  'data-testid': 'expenseSettingHandleChangeAmount',
                }}
                onChange={expenseSettingHandleChangeAmount}
              />
            </FormControl>
          </div>

          <div style={popUpStyle.twocolumnlayout}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Effective From'}
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                  minDate={new Date(previousMonthDate)}
                  value={expenseSettingStartDates}
                  maxDate={expenseSettingEndDates}
                  PopperProps={DialogProps}
                  onChange={expenseSettingHandleChangetaStartDate}
                  label="start date"
                  renderInput={renderInput}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              fullWidth
              sx={[popUpStyle.marginBottom15, {paddingLeft: '5px'}]}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Valid upto'}
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                  value={expenseSettingEndDates}
                  disabled={true}
                  maxDate={new Date(expenseSettingMaxDate)}
                  minDate={expenseSettingStartDates}
                  PopperProps={DialogProps}
                  onChange={expenseSettingHandleChangetaEndDate}
                  label="end date"
                  renderInput={renderInput}
                />
              </LocalizationProvider>
            </FormControl>
          </div>

          <div style={popUpStyle.twocolumnlayout}>
            {!expenseSettingStatus && (
              <FormGroup sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>{'Status'}</Typography>
                <Stack
                  direction="row"
                  paddingLeft={1}
                  spacing={2}
                  alignItems="center">
                  <Typography sx={popUpStyle.labelSizingAntSwitch}>
                    In-Active
                  </Typography>
                  <AntSwitch
                    checked={expenseSettingStatus}
                    data-testid="expenseSettingHandleChangeAmount"
                    onChange={ExpenseSettingStatusHandleChange}
                    inputProps={{'aria-label': 'ant design'}}
                  />
                  <Typography sx={popUpStyle.labelSizingAntSwitch}>
                    Active
                  </Typography>
                </Stack>
              </FormGroup>
            )}
            <FormGroup sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Bill Required'}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                paddingLeft={1}
                spacing={2}>
                <Typography sx={popUpStyle.labelSizingAntSwitch}>No</Typography>
                <AntSwitch
                  checked={billRequired}
                  inputProps={{'aria-label': 'ant design'}}
                  data-testid="expenseSettingHandleChangeAmount"
                  onChange={handleChangeIsBillRequired}
                />
                <Typography sx={popUpStyle.labelSizingAntSwitch}>
                  Yes
                </Typography>
              </Stack>
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} justifyContent="center" width={'100%'}>
            <Button
              data-testid="onResetClick"
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={onResetClick}>
              Reset
            </Button>

            <Button
              data-testid="onSaveClick"
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={onSaveClick}
              disabled={expenseSettingValidate}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
